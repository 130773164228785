import * as React from 'react';
import './layout.css'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router';
import { Collapse, Icon, SwipeableDrawer } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@mui/material/Menu';
import { Avatar } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { TokenService } from '../../services/token.service';
import { getConfiguration } from '../../api-services/employee-services';
import { goToDefaultRoute } from '../../_guards/Auth.guard';
import { useDispatch } from 'react-redux';
import { setDashBoardConfig, setLeaveConfig, setLeaveReviewConfig, setOvertimeConfig, setTeamConfig } from '../../redux/actions/actions';
import FetchApi from '../../fetch-api/fetch-api';
import { Role } from '../../model/token-model/role.model';
import SwipeableTemporaryDrawer from '../../widgets/drawer/drawer';
import { DrawerService } from '../../services/snackBehaviorSubject/snackBehaviorSubject';

const drawerWidth = 252;


interface MenuItems {
    id: number;
    name: string;
}

interface MenuData extends MenuItems {
    icon: string | null;
    path?: string;
    subRoutes?: MenuData[];
}

interface Props {
    window?: () => Window;
    children?: React.ReactNode;
}

const SideBar = (props: Props) => {
    const { window, children } = props;
    const [sidNavMenuItem, setSideNavMenuItem] = React.useState<any[]>([])
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClosing, setIsClosing] = React.useState(false);
    const [openSub, setOpenSub] = React.useState<string | null>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const role = TokenService.getRole()
    const fetchApi = FetchApi()


    React.useEffect(() => {
        if (role === Role.COREMEMBERS) {
            fetchApi.fetchAdminTeam()
        } else {
            fetchApi.fetchUserTeam()
        }
    }, [])

    function transformIcons(menudata: any) {
        return menudata.map((item: any) => {
            item.icon = <Icon>{item.icon}</Icon>;
            if (item.subRoutes.length > 0) {
                item.subRoutes = transformIcons(item.subRoutes);
            } else {
                delete item.subRoutes;
            }
            return item;
        });
    }

    const fetchData = () => {
        getConfiguration().then((response: any) => {
            if (response.data.success) {
                const menu: MenuData[] = transformIcons(response.data.payload.menuItem).sort((a: { id: number; }, b: { id: number; }) => a.id - b.id);
                setSideNavMenuItem(menu)
                dispatch(setTeamConfig(response.data.payload.team_configs))
                dispatch(setDashBoardConfig(response.data.payload.dashboardConfig))
                dispatch(setOvertimeConfig(response.data.payload.overTime))
                dispatch(setLeaveConfig(response.data.payload.leaveConfigs))
                dispatch(setLeaveReviewConfig(response.data.payload.leaveReviewConfigs
                ))
            }
        })
    }

    React.useEffect(() => {
        fetchData()
    }, [])


    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const routesPage = (text: any) => {
        navigate(text.path)
    }

    const handleItemClick = (text: any) => {
        setOpenSub(openSub === text.path ? null : text.path);

    }

    const handleLogOut = (popupState: any) => {
        goToDefaultRoute()
    };

    const handleAccount = (popupState: any) => {
        popupState.close();
        navigate("account")
    }
    const OnNavigateHome = () => {
        navigate('dashboard')
    }

    const drawer = (
        <div className='sidebar-drawer-container'>
            <div style={{ height: '100%' }}>
                <div className='drawer-heading'>
                    <Toolbar className='sidebar-head' >
                        <Typography variant="h6" noWrap component="div" style={{ width: '100%' }}>
                            <img src={require('../../assets/logo/logo.png')} alt='n' style={{ height: '30px', width: '100px' }} className='cursor-pointer' onClick={OnNavigateHome} />
                        </Typography>
                    </Toolbar>
                </div>
                <div className='sidebar-list-conatiner'>
                    <div style={{ width: '100%' }}>
                        <List style={{ color: 'var(--primaryColor)' }}>
                            {sidNavMenuItem.map((text, index) => (
                                <React.Fragment key={index}>
                                    <ListItem disablePadding onClick={() => {
                                        routesPage(text);
                                        text.subRoutes && handleItemClick(text);
                                    }
                                    } >
                                        <ListItemButton >
                                            <ListItemIcon style={{ color: 'var(--primaryColor)' }}>
                                                {text.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={text.name} />
                                            {
                                                text.subRoutes ? (openSub === text.path ? <ExpandMore /> : <ExpandLess />) : ''
                                            }
                                        </ListItemButton>
                                    </ListItem>
                                    {
                                        text.subRoutes && text.subRoutes.length > 0 &&
                                        <Collapse in={openSub === text.path}>
                                            <List sx={{ pl: 4 }}>
                                                {text.subRoutes.map((subText: any, subIndex: number) => (
                                                    <ListItem key={subIndex} disablePadding onClick={() => routesPage(subText)}>
                                                        <ListItemButton>
                                                            <ListItemIcon style={{ color: 'var(--primaryColor)' }}>
                                                                {subText.icon}
                                                            </ListItemIcon>
                                                            <ListItemText primary={subText.name} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Collapse>
                                    }
                                </React.Fragment>
                            ))}
                        </List>
                    </div>
                    <div style={{ width: '100%' }}>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                <React.Fragment>
                                    <div {...bindTrigger(popupState)} className='profile-menu-container'>
                                        <div className="icon">

                                            <Avatar className='avatar' alt={TokenService.extractToken()?.name} src="/static/images/avatar/1.jpg" />
                                        </div>
                                        <div>
                                            {TokenService.extractToken()?.name}
                                        </div>

                                    </div>


                                    <Menu {...bindMenu(popupState)} className='profile-menu' style={{ flexDirection: 'column' }}>

                                        <MenuItem onClick={() => handleAccount(popupState)}>My account</MenuItem>
                                        <MenuItem onClick={() => handleLogOut(popupState)}>Logout</MenuItem>
                                    </Menu>
                                </React.Fragment>
                            )}
                        </PopupState>
                    </div>
                </div>
            </div>
        </div >
    );


    // Remove this const when copying and pasting into your project.
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" style={{ width: '100%' }} >
                        <span onClick={OnNavigateHome} className='cursor-pointer'>HRMS</span>
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
            >
                <Toolbar />
                <Typography>
                    {children}
                </Typography>
            </Box>
        </Box>
    );
}

export default SideBar;