import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import './leave-review.css';
import ApproveRejectCell from '../../../custom-cell-render/approve-reject-cell';
import { downloadLeaveAttachment, getAllLeaveOfEmp } from '../../../../api-services/employee-services';
import { AgGridReact } from 'ag-grid-react';
import CustomDetailsCellRender from '../../../custom-details-cell-render/custom-details-cell-render';
import LeaveReviewContainer from '../../../../componant/gridContainer/gridContainer';
import AgGridContainer from '../../../../componant/gridContainer/gridContainer';
import CustomTeamDetailsCell from '../../../custom-team-details-cell-render/customTeamDetailsCell';
import { formateHifDate } from '../../../../util_service/dateFormater/dateFormater';
import { useSelector } from 'react-redux';
import { getColumnDef } from '../../../../util_service/filterGridColumnDefination/filterGridColumnDefination';

export default function LeaveReview() {
    const state = useSelector((state: any) => state.configuration)
    const [colDef, setColDef] = useState<any[]>([])
    const [rowData, setRowData] = useState([]);
    const sortingRowData = (leaveData: any) => {
        leaveData.sort((a: any, b: any) => {
            if (a.leaveStatus === "Pending") return -1;
            if (b.leaveStatus === "Pending") return 1;
            return 0;
        });
        setRowData(leaveData)

    }


    useEffect(() => {
        if (state.leave_review_config.length > 0) {
            const filteredColumnDefs = getColumnDef(columnDefs, state.leave_review_config)
            setColDef(filteredColumnDefs)
        }
    }, [state])

    const fetchData = () => {
        getAllLeaveOfEmp().then((response: any) => {
            if (response?.data.success) {
                sortingRowData(response.data.payload)
            }
        }).catch()
    }
    useEffect(() => {
        fetchData()
    }, []);

    const onDownloadAttachment = (e: any, id: any, fileName: any, name: any) => {
        const filesName = `${name}_${fileName}`
        downloadLeaveAttachment(id).then((response: any) => {
            if (response?.data) {
                console.log(response)
                const linkSource = window.URL.createObjectURL(response.data);
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = filesName;
                downloadLink.click();
            }
        })
    }
    const handleEditEmployees = (params: boolean) => {
        if (params) {
            fetchData()
        }
    }

    const columnDefs: any = [
        {
            headerName: "Name",
            field: "name",
            minWidth: 100,
            flex: 1,
            resizable: true,
            filter: true,
            pinned: 'left',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Team",
            field: "teamsName",
            minWidth: 200,
            flex: 1,
            resizable: true,
            filter: true,
            pinned: 'left',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
            cellRenderer: (params: any) => (
                <CustomTeamDetailsCell value={params} actionStatus={true} title={"Team"} />
            )
        },
        {
            headerName: "Leave Type",
            field: "leaveType",
            filter: true,
            minWidth: 130,
            flex: 1,
            lockPinned: true
        },
        {
            headerName: "From Date",
            field: "fromDate",
            minWidth: 120,
            flex: 1,
            filter: true,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
            cellRenderer: (params: any) => {
                const formattedValue = formateHifDate(params.value);
                return formattedValue;
            }
        },
        {
            headerName: "To Date",
            field: "toDate",
            filter: true,
            minWidth: 110,
            flex: 1,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
            cellRenderer: (params: any) => {
                const formattedValue = formateHifDate(params.value);
                return formattedValue;
            }
        },
        {
            headerName: "Days",
            field: "noOfDays",
            minWidth: 100,
            flex: 1,
            filter: true,
        },
        {
            headerName: "Session",
            field: "session",
            minWidth: 130,
            flex: 1,
            lockPinned: true,
            cellClass: 'flex-start-center',
            filter: true,
        },
        {
            headerName: "Schedule ",
            field: "leavePeriod",
            lockPinned: true,
            cellClass: 'flex-start-center',
            filter: true,
            minWidth: 130,
            flex: 1,
        },
        {
            headerName: "Reason",
            field: "reasonForLeave",
            lockPinned: true,
            minWidth: 130,
            flex: 1,
            cellRenderer: (params: any) => (
                <CustomDetailsCellRender data={params} flag={false} title='Reason For Leave' />
            ),
        },
        {
            headerName: "Attachment",
            field: "attachment",
            minWidth: 130,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
            cellRenderer: (params: any) => {
                let content;
                if (params.data.attachment !== 'NA') {
                    const parts = params.data.attachment.split('_');
                    const filename = parts[parts.length - 1].trim();
                    const fileNames = filename;
                    content = (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => onDownloadAttachment(e, params.data.leaveId, filename, params.data.name)}
                            >{fileNames}</div>
                        </div>
                    );
                } else {
                    content = (
                        <div className='leave-review-field-container-no-file'>
                            {params.data.attachment}
                        </div>
                    );
                }

                return (
                    <div style={{ width: '100%' }}>
                        {content}
                    </div>
                );
            }
        },
        {
            headerName: "Action",
            field: "leaveStatus",
            filter: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
            minWidth: 180,
            cellRenderer: (params: any) => (
                <>
                    <ApproveRejectCell value={params.data} handleEditEmployees={handleEditEmployees} />
                </>

            ),
        },
    ];

    return (
        <div className='main'>
            <div className='main-container'>
                <div className='heading heading-start-center height-40'>
                    <h2>Leave Review</h2>
                </div>
                <div className='cal-40' >
                    <AgGridContainer rowData={rowData} handleEditEmployees={handleEditEmployees} columnDefs={colDef} />
                </div>
            </div>
        </div>
    );
}
