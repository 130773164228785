import React, { useState, useEffect } from 'react';
import './leave.css';
import { getAllLeaveDetails } from '../../../api-services/employee-services';
import { Avatar, Modal } from '@mui/material';
import { Box, minWidth, textAlign } from '@mui/system';
import EditNoteIcon from '@mui/icons-material/EditNote';
import EditLeave from './editLeave/editLeave';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Tooltip } from '@mui/material';
import { Grid } from '@material-ui/core';
import ButtonWrapper from '../../../widgets/button/button';
import ModalWrapper from '../../../widgets/modal/modal';
import AgGridContainer from '../../../componant/gridContainer/gridContainer';
import { useSelector } from 'react-redux';
import { getColumnDef } from '../../../util_service/filterGridColumnDefination/filterGridColumnDefination';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid rgb(39,112,222)',
        borderRadious: "2px",
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        // boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const style = {

    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: 0.9,
        sm: 0.8,
        md: 400
    },
    bgcolor: 'white',
    boxShadow: 24,
    p: 2,
    borderRadius: 2

};

interface LeaveDetail {
    leave_type: string;
    total: number;
    balance: number;
}

interface Employee {
    employee_id: string;
    employee_name: string;
    casual_leave?: string;
    casual_total?: number;
    casual_balance?: number;
    special_leave?: string;
    special_total?: number;
    special_balance?: number;
    medical_leave?: string;
    medical_total?: number;
    medical_balance?: number;
    vacation_leave?: string;
    vacation_total?: number;
    vacation_balance?: number;
}

const AdminLeave = () => {
    const state = useSelector((state: any) => state.configuration);
    const [leaveDetails, setLeaveDetails] = useState<Employee[]>([])
    const [isEditable, setIsEditable] = useState<boolean>(false)
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [colDef, setColDef] = useState<any[]>([])
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    useEffect(() => {
        if (state.leave_config.length > 0) {
            const filteredColumnDefs = getColumnDef(columnDefs, state.leave_config)
            setColDef(filteredColumnDefs)
        }
    }, [state])


    const columnDefs = [
        {
            headerName: "ID",
            field: "employee_id",
            resizable: true,
            filter: true,
            minWidth: 130,
            flex: 1
        },
        {
            headerName: "Name",
            field: "employee_name",
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 150,
        },
        {
            headerName: "Casual Leave",
            field: "casual_total",
            resizable: true,
            filter: true,
            minWidth: 140,
            flex: 1,


        },
        {
            headerName: "Balance",
            field: "casual_balance",
            resizable: true,
            filter: true,
            minWidth: 110,
            flex: 1,

        },
        {
            headerName: "Medical Leave",
            field: "medical_total",
            resizable: true,
            filter: true,
            minWidth: 155,
            flex: 1,

        },
        {
            headerName: "Balance",
            field: "medical_balance",
            resizable: true,
            filter: true,
            minWidth: 110,
            flex: 1,

        },
        {
            headerName: "Special Leave",
            field: "special_total",
            resizable: true,
            filter: true,
            minWidth: 140,
            flex: 1,

        },
        {
            headerName: "Balance",
            field: "special_balance",
            resizable: true,
            filter: true,
            minWidth: 110,
            flex: 1,

        },
        {
            headerName: "Vacation Leave",
            field: "vacation_total",
            resizable: true,
            filter: true,
            minWidth: 150,
            flex: 1,

        },
        {
            headerName: "Balance",
            field: "vacation_balance",
            resizable: true,
            filter: true,
            minWidth: 110,
            flex: 1,

        }
    ]

    const convertEmployeeData = (employees: any[]): Employee[] => {
        return employees.map(employee => {
            const convertedEmployee: Employee = {
                employee_id: employee.employee_id,
                employee_name: employee.employee_name
            };

            employee.leaveDetails.forEach((leave: LeaveDetail) => {
                switch (leave.leave_type) {
                    case "Casual":
                        convertedEmployee.casual_total = leave.total;
                        convertedEmployee.casual_balance = leave.balance;
                        break;
                    case "Special":
                        convertedEmployee.special_total = leave.total;
                        convertedEmployee.special_balance = leave.balance;
                        break;
                    case "Medical":
                        convertedEmployee.medical_total = leave.total;
                        convertedEmployee.medical_balance = leave.balance;
                        break;
                    case "Vacation":
                        convertedEmployee.vacation_total = leave.total;
                        convertedEmployee.vacation_balance = leave.balance;
                        break;
                    default:
                        break;
                }
            });

            return convertedEmployee;
        });
    };

    const fetchData = () => {
        getAllLeaveDetails().then((response: any) => {
            if (response?.data.success) {
                console.log(response.data.payload)
                const converted = convertEmployeeData(response.data.payload)
                setLeaveDetails(converted)
            }


        }).catch()
    }
    useEffect(() => {
        fetchData()
    }, [])

    const onLeaveEdit = () => {
        handleModalOpen()
        setIsEditable(true)
    }
    const getLeaveDetails = (flag: boolean) => {
        if (flag)
            fetchData()
    }
    return (
        <>
            <div className='main'>
                <div className='main-container'>
                    <div className='heading flex-center-space-between height-40'>
                        <Grid item xs={6} sm={6}>
                            <h2>Leave Details</h2>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Avatar variant='circular' className='avatar'>
                                <ButtonWrapper buttonType='edit' onClick={onLeaveEdit} tooltipTitle='Edit Leave' tooltipPlacement='left' className='edit-icon-style' />
                            </Avatar>
                            <ModalWrapper open={modalOpen} close={handleModalClose} children={<EditLeave onClose={handleModalClose} isEditable={isEditable} getLeaveDetails={getLeaveDetails} />} />
                        </Grid>
                    </div>

                    <div className='sub-container cal-40'>
                        <div style={{ height: '100%' }}>
                            <AgGridContainer rowData={leaveDetails} columnDefs={colDef} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminLeave;
