import { Role } from "../../model/token-model/role.model"
import { TokenService } from "../../services/token.service"

export const Path = () => {
    const role: Role | null = TokenService.getRole()
    let basePath = ''
    if (role === Role.ADMIN) {
        basePath = '/admin'
    } else {
        basePath = '/user'
    }

    const admin = [
        { name: 'pendingLeaveForApproval', _path: `${basePath}/leaveReview` },
        { name: 'workFromHome', _path: `${basePath}/workFromHome` },
        { name: 'team', _path: `${basePath}/teamManagement` },
        { name: 'project', _path: `${basePath}/teamManagement` },
    ]

    const user = [
        { name: 'team', _path: `${basePath}/teamManagement` },
        { name: 'project', _path: `${basePath}/teamManagement` },
        { name: 'attendance', _path: `${basePath}/attendance` },
        { name: 'taskList', _path: `${basePath}/taskList` }
    ]
    return { admin, user }
}