import { AgGridReact } from 'ag-grid-react'
import React, { useEffect, useState } from 'react'
import { downloadLeaveAttachment } from '../../api-services/employee-services';
import './gridContainer.css'
import { RowStyle } from 'ag-grid-community';

interface LeaveReviewContainerProps {
    rowData: any[],
    handleEditEmployees?: (params: boolean) => void,
    columnDefs?: any[],
    editType?: string,
    suppressRowClickSelection?: boolean,
    stopEditingWhenCellsLoseFocus?: boolean,
    singleClickEdit?: boolean,
    onGrid?: (params: any) => void,
    rowSelection?: "single" | "multiple",
    enableRangeSelection?: true,
    onCellValueChanged?: (e: any) => void,
    pinnedBottomRowData?: any,
    rowStyle?: (params: any) => RowStyle | undefined;
    onSelectionChanged?: (params: any) => void;
    onRowValueChange?: (params: any) => void;
    defaultColDef?: any,
    onCellClicked?: (params: any) => void
}

const AgGridContainer = (props: LeaveReviewContainerProps) => {
    console.log(props, "Props")
    const [rowData, setRowData] = useState<any[]>([])
    const [colDef, setColDef] = useState<any[]>([])

    console.log(props)
    useEffect(() => {
        if (props.rowData && props.rowData !== null && props.rowData.length > 0) {
            setRowData(props.rowData)
        } else {
            setRowData([])
        }
        if (props.columnDefs && props.columnDefs.length > 0) {
            setColDef(props.columnDefs)
        }
    }, [props])

    const handleEditEmployees = (params: boolean) => {
        if (props.handleEditEmployees) {
            props.handleEditEmployees(params)
        }
    }

    const onDownloadAttachment = (e: any, id: any, fileName: any, name: any) => {
        const filesName = `${name}_${fileName}`
        downloadLeaveAttachment(id).then((response: any) => {
            if (response?.data) {
                console.log(response)
                const linkSource = window.URL.createObjectURL(response.data);
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = filesName;
                downloadLink.click();
            }
        })
    }

    const onGridReady = (params: any) => {
        if (props.onGrid)
            props.onGrid(params)
    }

    const getRowStyle = (params: any): RowStyle | undefined => {
        if (props.rowStyle)
            return props.rowStyle(params);
        // Return undefined if props.rowStyle is not provided or returns undefined
        return undefined;
    }


    return (
        <div className='grid-container'>
            <AgGridReact
                defaultColDef={props.defaultColDef}
                onGridReady={onGridReady}
                rowData={rowData}
                columnDefs={colDef}
                className="ag-theme-alpine"
                editType={props.editType === 'fullRow' ? 'fullRow' : undefined}
                suppressRowClickSelection={props.suppressRowClickSelection}
                stopEditingWhenCellsLoseFocus={props.stopEditingWhenCellsLoseFocus}
                singleClickEdit={props.singleClickEdit}
                rowSelection={props.rowSelection === "single" || props.rowSelection === "multiple" ? props.rowSelection : undefined}
                enableRangeSelection={props.enableRangeSelection}
                onCellValueChanged={props.onCellValueChanged}
                getRowStyle={getRowStyle}
                pinnedBottomRowData={props.pinnedBottomRowData}
                onSelectionChanged={props.onSelectionChanged}
                suppressMenuHide={true}
                onRowValueChanged={props.onRowValueChange}
                onCellClicked={props.onCellClicked}
            />
        </div>

    )
}

export default AgGridContainer
