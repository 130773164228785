import React, { useEffect, useState } from 'react'
import "./login.css"
import { useNavigate } from 'react-router';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { postLogin } from '../../api-services/employee-services';
import ForgotPassword from '../../componant/forgot-password/forgot-password';
import { SnackbarService } from '../../services/snackBehaviorSubject/snackBehaviorSubject';
import { modalStyle } from '../../style/style';
import ModalWrapper from '../../widgets/modal/modal';
import { TokenService } from '../../services/token.service';
import { Role } from '../../model/token-model/role.model';

interface LoginCredentials {
    username: string;
    password: string;
}


export const Login = () => {
    const style = modalStyle()
    const snackbarService = SnackbarService()
    const [openModal, setOpen] = useState<boolean>(false);
    const handleOpen = (e: any) => {
        e.preventDefault()
        setOpen(true)
    };
    const handleClose = () => setOpen(false);
    const [credentials, setCredentials] = useState<LoginCredentials>({
        username: '',
        password: '',
    });

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const navigate = useNavigate()
    const loginToken = TokenService.getToken()

    useEffect(() => {
        if (loginToken !== null) {
            const token = loginToken
            checkTokenExpiration(token)
        } else {
            navigate('/')

        }

    }, [loginToken])



    function checkTokenExpiration(token: any) {
        const isExpire = TokenService.isExpired()
        const role = TokenService.getRole()
        if (!isExpire) {
            if (role === Role.ADMIN) {
                navigate('/admin/dashboard');
            } else {
                navigate('/user/dashboard');
            }
        } else {
            navigate('/')
        }

    }


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCredentials((prevCredentials: any) => ({
            ...prevCredentials,
            [name]: value,
        }));

    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const loginData = {
            username: credentials.username,
            password: credentials.password
        }
        TokenService.clearToken()
        postLogin(loginData).then((response: any) => {
            console.log(response)
            if (response?.data.success) {
                TokenService.setToken(response.data.payload)
                if (TokenService.getRole() === Role.ADMIN) {
                    navigate('/admin/dashboard');
                } else {
                    navigate('/user/dashboard');
                }
            } else {
                snackbarService.next({ data: { open: true, severity: 'error', message: response?.data.message } })
            }
        }).catch();


    };


    return (
        <div className='main'>
            <div className="wrapper">
                <div className="container">
                    <div className="col-left">
                        <div className="login-text">
                            <h2>
                                <img src={require('../../assets/logo/logo.png')} alt="" />
                            </h2>
                            <p>
                                <b><i>AirLegit© enables companies to improve their travelers' end-to-end journeys.</i></b>
                                <br />
                                We work with airlines, online travel agencies, travel management companies and travel technology innovators to improve their customer experience and generate additional ancillary revenue.
                            </p>

                            <Link to="https://airlegit.com" target="_blank" className='link_btn btn-1'>Read More</Link>

                        </div>
                    </div>
                    <div className="col-right">
                        <div className="login-form">
                            <h2>Login</h2>
                            <form onSubmit={handleSubmit}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    gap: "20px"
                                }}>
                                    <div>
                                        <input type="text" placeholder="Username" name="username"
                                            value={credentials.username}
                                            onChange={handleChange} required />
                                    </div>
                                    <div className="password-input">
                                        <div className="input-container">
                                            <input
                                                name="password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={credentials.password}
                                                onChange={handleChange}
                                                placeholder="Password"
                                            />
                                            {showPassword ? <FaEyeSlash
                                                size={23}
                                                onClick={togglePasswordVisibility}
                                                className="toggle-password-button"
                                            /> : <FaEye
                                                size={23}
                                                onClick={togglePasswordVisibility}
                                                className="toggle-password-button"

                                            />
                                            }

                                        </div>
                                    </div>

                                    <input className="btn" type="submit" value="Sign In" />


                                </div>

                            </form>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",

                            }}>
                                <div style={{
                                    textDecoration: "none",
                                    color: 'var(--primaryColor)',
                                    padding: '5px',
                                    cursor: 'pointer'
                                }}
                                    onClick={handleOpen}
                                >Forgot password?</div>
                                <ModalWrapper open={openModal} close={handleClose} children={<ForgotPassword onClose={handleClose} />} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
