import React, { useEffect, useState } from 'react'
import './teamManagement.css'
import CustomTeamDetailsCell from '../../custom-team-details-cell-render/customTeamDetailsCell';
import EditTeamDetails from '../../edit-team-details-cell/editTeamDetails';
import FetchApi from '../../../fetch-api/fetch-api';
import { useSelector } from 'react-redux';
import AddTeamProject from '../../../componant/addTeamProject/addTeamProject';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Tooltip } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import jwt from 'jwt-decode';
import { JwtModel } from '../../../model/token-model/token.model';
import ModalWrapper from '../../../widgets/modal/modal';
import AgGridContainer from '../../../componant/gridContainer/gridContainer';
import { TokenService } from '../../../services/token.service';
import { Role } from '../../../model/token-model/role.model';
import { formateHifDate } from '../../../util_service/dateFormater/dateFormater';
import CustomCellRender from '../../custom-cell-render/custom-cell-render';
import { deleteTeam } from '../../../api-services/employee-services';
import { SnackbarService } from '../../../services/snackBehaviorSubject/snackBehaviorSubject';
import { WrapText } from '@mui/icons-material';
import { minWidth } from '@mui/system';
import { filter } from 'rxjs';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid rgb(39,112,222)',
        borderRadious: "2px",
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        // boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));



const UserTeamManagement = () => {
    const snackbarService = SnackbarService()
    const state = useSelector((state: any) => state.team)
    const [rawData, setRawData] = useState([])
    const [actionStatus, setActionStatus] = useState<boolean>(false)
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState(null)
    const role = TokenService.getRole()
    const handleClose = () => {
        setOpenModal(false)
        setSelectedRow(null)
    };
    const fetchApi = FetchApi()


    useEffect(() => {
        if (role === Role.COREMEMBERS && state.admin_team !== null) {
            setRawData(state.admin_team)
        } else if (role === Role.USER && state.user_team !== null) {
            setRawData(state.user_team)
        } else {
            setRawData([])
        }
    }, [state])

    useEffect(() => {
        if (state.team_config.length > 0) {
            const filteredColumnDefs = colDef.filter((column: any) => {
                if (column.field === "") {
                    return true; // Include empty fields
                } else if (column.field === 'sno') {
                    return true
                } else {
                    return state.team_config.some((item: any) => item.name === column.field);

                }
            });
            setColumnDefs(filteredColumnDefs)
            console.log(filteredColumnDefs, "Col def")
        }
    }, [state])



    const [columnDefs, setColumnDefs] = useState<any[]>([])

    const colDef = [
        {
            headerName: "S.No",
            field: "sno",
            resizable: true,
            width: 70,
            // flex: 1,
        },
        {
            headerName: "Team Name",
            field: "teamName",
            minWidth: 200,
            flex: 1,
            resizable: true,
            filter: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Project Name",
            field: "projectName",
            minWidth: 110,
            flex: 1,
            filter: true,
            resizable: true,
            pinned: 'left',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Client",
            field: "projectClient",
            minWidth: 200,
            flex: 1,
            filter: true,
            lockPinned: true,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Details",
            field: "projectDetails",
            minWidth: 200,
            flex: 1,
            filter: true,
            lockPinned: true,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Start Date",
            field: "projectStartDate",
            minWidth: 130,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellRenderer: (params: any) => {
                const formattedValue = formateHifDate(params.value);
                return formattedValue;
            },
            cellClass: 'flex-start-center',
        },
        {
            headerName: "End Date",
            field: "projectDeliveryDate",
            minWidth: 130,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellRenderer: (params: any) => {
                const formattedValue = formateHifDate(params.value);
                return formattedValue;
            },
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Demo Date",
            field: "projectDemoDate",
            minWidth: 130,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellRenderer: (params: any) => {
                const formattedValue = params.value ? formateHifDate(params.value) : null;
                return formattedValue;
            },
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Project Leader",
            field: "projectLead",
            minWidth: 200,
            flex: 1,
            filter: true,
            lockPinned: true,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellClass: 'flex-start-center',

        },
        {
            headerName: "Frontend Member",
            field: "projectFrontEndMembers",
            minWidth: 170,
            flex: 1,
            lockPinned: true,
            filter: 'agTextColumnFilter',
            valueGetter: (params: any) => {
                return params.data.projectFrontEndMembers
                    .map((member: any) => member.name)
                    .join(', ');
            },
            cellRenderer: (params: any) => (
                <CustomTeamDetailsCell value={params} actionStatus={true} title={"Frontend Members"} />
            )

        },
        {
            headerName: "Backend Member",
            field: "projectBackEndMembers",
            minWidth: 170,
            flex: 1,
            lockPinned: true,
            filter: 'agTextColumnFilter',
            valueGetter: (params: any) => {
                return params.data.projectBackEndMembers
                    .map((member: any) => member.name)
                    .join(', ');
            },
            cellRenderer: (params: any) => (
                <CustomTeamDetailsCell value={params} title={"Backend Members"} />
            )

        },
        {
            headerName: "Team Member",
            field: "teamMembers",
            minWidth: 200,
            flex: 1,
            resizable: true,
            filter: true,
            lockPinned: true,
            cellRenderer: (params: any) => (
                <CustomTeamDetailsCell value={params} title={"Team Members"} />
            ),


        },
        {
            headerName: "Status",
            field: "projectWorkingStatus",
            minWidth: 200,
            flex: 1,
            filter: true,
            lockPinned: true,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Remarks",
            field: "projectRemark",
            minWidth: 200,
            flex: 1,
            filter: true,
            lockPinned: true,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellClass: 'flex-start-center',
        }
    ]

    useEffect(() => {

    }, [])

    useEffect(() => {
        if (role === Role.COREMEMBERS) {
            const actionfield = {
                headerName: "Action",
                field: "",
                minWidth: 100,
                flex: 1,
                resizable: true,
                filter: true,
                cellRenderer: (params: any) => (
                    <CustomCellRender value={params} onDelete={handleDeleteTeam} onEdit={handleEditTeam} />
                ),
                lockPinned: true,

            }

            setColumnDefs((prevColumnDefs) => [...prevColumnDefs, actionfield])
        }


    }, [state])

    const handleDeleteTeam = (team: any) => {
        deleteTeam(team.projectId).then((response: any) => {
            if (response?.data.success) {
                snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
                fetchApi.fetchAdminTeam()

            } else {
                snackbarService.next({ data: { open: true, severity: 'error', message: response.data.message } })
            }
        })
    }

    const handleEditTeam = (team: any) => {
        setSelectedRow(team)
        setOpenModal(true)
        setActionStatus(true)
    }

    const onCreateTeam = () => {
        setOpenModal(true)
        setActionStatus(false)
    }

    const handleModalClose = (flag: boolean) => {
        setOpenModal(flag)
    }

    return (
        <div className='main'>
            <div className='main-container'>
                <div className='heading heading-center-space-between height-40'>
                    <h2>Team Management</h2>
                    <div className='flex-end-center'>
                        {role !== Role.USER ? <LightTooltip title="Create Team" placement='right'>< AddCircleIcon
                            className='circular-add-button'
                            sx={{ fontSize: "200%" }}
                            onClick={onCreateTeam}
                        />
                        </LightTooltip> : ''}
                        <ModalWrapper open={openModal} close={handleClose} children={<AddTeamProject onClose={handleClose} actionStatus={actionStatus} data={selectedRow} handleModalClose={handleModalClose} />} />
                    </div>
                </div>
                <div className='cal-40'>
                    <AgGridContainer rowData={rawData} columnDefs={columnDefs} editType='fullRow' suppressRowClickSelection={true} enableRangeSelection={true} stopEditingWhenCellsLoseFocus={true} singleClickEdit={true} />
                </div>
            </div>
        </div>
    )
}

export default UserTeamManagement
