import React, { useEffect, useMemo, useState } from 'react'
import './task.css'
import ButtonWrapper from '../../../widgets/button/button'
import ModalWrapper from '../../../widgets/modal/modal';
import AddTask from '../../../componant/addTask/addTask';
import AgGridContainer from '../../../componant/gridContainer/gridContainer';
import { StatusModel } from '../../../model/status-model/status.model';
import AddStatusChangeReason from '../../../componant/addStatusChangeReason/addStatusChangeReason';
import ViewTaskReason from '../../../componant/viewTaskReason/viewTaskReason';
import { SnackbarService } from '../../../services/snackBehaviorSubject/snackBehaviorSubject';
import { updateDueDate, updateStatus, updateTask } from '../../../api-services/employee-services';
import { TokenService } from '../../../services/token.service';
import FetchApi from '../../../fetch-api/fetch-api';
import { useSelector } from 'react-redux';
import { formateISODate, formateZoffsetDate } from '../../../util_service/dateFormater/dateFormater';
import { Role } from '../../../model/token-model/role.model';
import { ProjectStatusModel } from '../../../model/project-status-model/project-status-model';
import AuditCellRender from '../../audit-cell-render/audit-cell-render';
import { getStatus } from '../../../util_service/status/status';
import { filterParams, filterParamsDate } from '../../../util_service/agGrid/dateFilter/dateFilter';
import LightTooltip from '../../../widgets/tooltip/tooltip';
import { GridApi } from 'ag-grid-community';
import { filterModel } from '../../../util_service/agGrid/filterModel/filterModel';

interface TaskData {
    id: number;
    projectName: string;
    description: string;
}

interface statusProps {
    taskId: number,
    value: string,
    reason: string,
    uniqueId: number
}
interface completionProps {
    taskId: string,
    completionDate: string,
    reason: string,
    uniqueId: number
}

interface Status {
    id: number;
    value: string;
    reason: string | null;
    statusUpdateDate: string;
}

interface CompletionTarget {
    id: number;
    completionDate: string;
    reason: string | null;
}

interface TaskProps {
    id: number;
    employeeId: string;
    projectId: string;
    projectName: string,
    description: string;
    createdDate: string;
    uniqueId: number,
    status: Status[];
    completionTargets: CompletionTarget[];
}

interface UpdateStatus {
    taskId: number;
    value: string;
}

interface typeProps {
    type: string;
    field: string
}


const TaskList = () => {
    const task = useSelector((state: any) => state.task.userTask)
    const Token = TokenService.extractToken()
    const role = TokenService.getRole()
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const fetchApi = FetchApi()
    const snackbarService = SnackbarService()
    const state = useSelector((state: any) => state.team)
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [rowData, setRowData] = useState<TaskProps[]>([])
    const [originalRowData, setOriginalRowData] = useState<TaskProps[]>([])
    const [rowParams, setRowParams] = useState<any[]>([])
    const [updateButtonDisable, setUpdateButtonDisable] = useState<boolean>(true)
    const [statusValue, setStatus] = useState<statusProps[]>([])
    const [completion, setCompletion] = useState<completionProps[]>([])
    const [openReasonModal, setOpenReasonModal] = useState<boolean>(false);
    const [isSatusChange, setIsStatusChange] = useState<boolean>(false)
    const [isViewReason, setisViewReason] = useState<boolean>(false)
    const [reasonDetails, setReasonDetails] = useState<string>('')
    const [selectedRow, setSelectedRow] = useState<any[]>([])
    const [changeDetaction, setChangeDetection] = useState<any[]>([])
    const [detailsTitle, setDetailsTitle] = useState<string>('')
    const [type, setType] = useState<typeProps>()
    const [dropdownProject, setDropdrownProject] = useState<any[]>(['Untitle'])
    const [dropdownStatus, setDropdrownStatus] = useState<any[]>([{ name: 'Pending', value: 'PENDING', disable: true },
    { name: 'In Progress', value: 'IN_PROGRESS', disable: false },
    { name: 'On Hold', value: 'ON_HOLD', disable: false },
    { name: 'Completed', value: 'COMPLETED', disable: false },
    { name: 'Discarded', value: 'DISCARDED', disable: false },])
    const [editFieldValue, setEditFieldValue] = useState<TaskProps[]>([])
    const [project, setProject] = useState<any[]>([])

    useEffect(() => {
        const projectNames = project.map(projects => projects.projectName);
        setDropdrownProject((preav: any) => {
            return [...preav, ...projectNames]
        })
    }, [project])

    useEffect(() => {
        if (role === Role.COREMEMBERS) {
            const filteredTeam = state.admin_team.filter((element: { projectWorkingStatus: string; }) => {
                if (element.projectWorkingStatus !== ProjectStatusModel.CLOSED) {
                    return true
                } else {
                    return false
                }
            });
            setProject(filteredTeam)
        } else {

            const filteredTeam = state.user_team.filter((element: { projectWorkingStatus: string; }) => {
                if (element.projectWorkingStatus !== ProjectStatusModel.CLOSED) {
                    return true
                } else {
                    return false
                }
            });
            setProject(filteredTeam)
        }
    }, [role, state.admin_team, state.user_team])

    console.log(project, "projectprojectprojectproject")

    const handleOpenReasonModal = () => {
        setOpenReasonModal(true)
    };
    const handleCloseReasonModal = () => {
        setOpenReasonModal(false);
        setisViewReason(false)
    }

    const fetchData = () => {
        if (Token)
            fetchApi.getUserTask(Token.sub)
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (task.length > 0) {
            setRowData(JSON.parse(JSON.stringify(task)))
            setOriginalRowData(JSON.parse(JSON.stringify(task)))
        }
    }, [task])

    const statusOption = [
        { name: 'Pending', value: 'PENDING', disable: true },
        { name: 'In Progress', value: 'IN_PROGRESS', disable: false },
        { name: 'On Hold', value: 'ON_HOLD', disable: false },
        { name: 'Completed', value: 'COMPLETED', disable: false },
        { name: 'Discarded', value: 'DISCARDED', disable: false },
    ]

    const handleChangeReason = (reason: any) => {
        reason.forEach((item: any) => {
            if (item.status !== undefined) {
                const updateStatus = {
                    taskId: item.taskId,
                    value: statusOption.find((items: any) => items.name === item.presentStatus)?.value,
                    reason: item.status.value,
                    uniqueId: item.uniqueId
                }
                const existingTaskIndex = statusValue.findIndex((existingTask: any) => existingTask.taskId === updateStatus.taskId
                );

                setStatus((prevState: any) => {
                    if (existingTaskIndex !== -1) {
                        return [
                            ...prevState.slice(0, existingTaskIndex),
                            updateStatus,
                            ...prevState.slice(existingTaskIndex + 1),
                        ];
                    } else {
                        return [...prevState, updateStatus];
                    }
                });


            }

            if (item.completion !== undefined) {
                const updateCompletion = {
                    taskId: item.taskId,
                    completionDate: item.presentDate,
                    reason: item.completion.value,
                    uniqueId: item.uniqueId
                }

                const existingTaskIndex = completion.findIndex((existingTask: any) => existingTask.taskId === updateCompletion.taskId
                );
                setCompletion((prevState: any) => {
                    if (existingTaskIndex !== -1) {
                        return [
                            ...prevState.slice(0, existingTaskIndex),
                            updateCompletion,
                            ...prevState.slice(existingTaskIndex + 1),
                        ];
                    } else {
                        return [...prevState, updateCompletion];
                    }
                });
            }
        });

        setChangeDetection(reason)

    }


    const onReasonView = (reason: string, field?: string) => {
        if (field === 'task') {
            setDetailsTitle('Task')
        } else {
            setDetailsTitle('Reason')
        }
        handleOpenReasonModal()
        setisViewReason(true)
        setReasonDetails(reason)
    }




    const colDefs = [
        {
            headerName: 'Project Name',
            field: 'projectName',
            cellEditor: 'agSelectCellEditor',
            editable: true,
            filter: true,
            resizable: true,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,

            minWidth: 100,
            flex: 1,
            cellEditorParams: {
                values: dropdownProject,
            },
            pinned: 'left',
            cellClass: 'flex-start-center',
        },
        {
            headerName: 'Task',
            field: 'description',
            editable: true,
            minWidth: 200,

            flex: 1,
            lockPinned: true,
            cellRenderer: (params: any) => {
                const description = params.node.data.description
                return <div className='reason-main-container' >
                    <LightTooltip title={description}>
                        <div className='reason-container'>
                            <div className='single-line-text'  >{description}</div>
                        </div>
                    </LightTooltip>
                </div>
            },
            pinned: ''
        },
        {
            headerName: 'Creation Date',
            field: 'createdDate',
            filter: 'agDateColumnFilter',
            minWidth: 150,

            flex: 1,
            lockPinned: true,
            valueFormatter: (params: any) => {
                const value = formateISODate(params.value)
                return value
            },
            filterParams: filterParams,
            pinned: ''
        },
        {
            headerName: 'Start Date',
            field: 'startDate',
            cellEditor: 'agDateCellEditor',
            editable: true,
            minWidth: 150,

            flex: 1,
            filter: true,
            lockPinned: true,
            valueFormatter: (params: any) => {
                const value = params.value ? formateISODate(params.value) : null
                return value
            },
            valueGetter: (params: any) => params.data.startDate,
            valueSetter: (params: any) => params.data.startDate = params.newValue ? formateZoffsetDate(params.newValue) : params.data.startDate,
            pinned: ''
        },
        {
            headerName: 'Status',
            editable: true,
            colId: 'status',
            minWidth: 150,

            flex: 1,
            lockPinned: true,
            filter: "agTextColumnFilter",
            "filterParams": { "maxNumConditions": 3, "defaultJoinOperator": "OR" },
            valueGetter: (params: any) => getStatus(params.data.status[0].value).value,
            valueSetter: (params: any) => params.data.status[0].value = statusOption.find(option => option.name === params.newValue)?.value,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: dropdownStatus.map(option => option.name),
            },
            pinned: ''
        },
        {
            headerName: 'Status Change Reason',
            field: 'status',
            minWidth: 180,

            flex: 1,
            lockPinned: true,
            cellRenderer: (params: any) => {
                console.log(params, "Paar")
                return <div className='reason-main-container' onClick={() => onReasonView(params.node.data.status[0].reason)}>
                    <div className='reason-container' >
                        <div className='single-line-text'  >{params?.node.data?.status[0].reason}</div>
                    </div>
                </div>
            },
            pinned: ''
        },
        {
            headerName: 'Due Date',
            field: 'completionTargets',
            minWidth: 150,

            flex: 1,
            lockPinned: true,
            editable: true,
            cellEditor: 'agDateCellEditor',
            filter: 'agDateColumnFilter',
            filterParams: filterParamsDate,
            pinned: '',
            valueFormatter: (params: any) => {
                const value = formateISODate(params.value)
                return value
            },
            valueGetter: (params: any) => params.data.completionTargets[0].completionDate,
            valueSetter: (params: any) => params.data.completionTargets[0].completionDate = params.newValue ? formateZoffsetDate(params.newValue) : params.data.completionTargets[0].completionDate,

        },
        {
            headerName: 'Due Date Change Reason',
            field: 'completionDate',
            pinned: '',

            minWidth: 200,
            flex: 1,
            lockPinned: true,
            cellRenderer: (params: any) => {
                const dueDateReason = params.node.data.completionTargets[0].completionDate !== null ? params.node.data.completionTargets[0].reason : null
                return <div className='reason-main-container' onClick={() => onReasonView(dueDateReason)}>

                    <div className='reason-container'>
                        <div className='single-line-text'  >{dueDateReason}</div>
                    </div>
                </div>
            }
        },

    ]




    useEffect(() => {
        if (role === Role.COREMEMBERS || role === Role.ADMIN) {
            const actionField = {
                headerName: 'Action',
                field: '',
                pinned: '',
                minWidth: 100,
                lockPinned: true,
                flex: 0,
                cellRenderer: (params: any) => <AuditCellRender value={params.data} />
            };

            colDefs.push(actionField)
        };
        const columnName = 'status'
        if (gridApi !== null) {
            const filterInstance = gridApi.getFilterInstance(columnName);
            if (filterInstance) {
                console.log(filterInstance, "Filter Instance")
                const [inProgressFilterModel, onHoldFilterModel, pendingFilterModel] = filterModel();

                filterInstance.setModel({
                    filterType: 'text',
                    operator: 'OR',
                    conditions: [
                        inProgressFilterModel,
                        onHoldFilterModel,
                        pendingFilterModel,
                    ],
                });

                gridApi.onFilterChanged();
            } else {
                console.log(`No filter instance found for column: ${columnName}`);
            }
        }



    }, [role, gridApi, colDefs]);
    const handleClose = () => {
        setOpenModal(false)
    };

    const handleOpen = () => {
        setOpenModal(true)
    }
    const onCreateTask = () => {
        setOpenModal(true)
    }

    const handleUpdateTask = () => {
        let allReasonsProvided = true;
        setType({ type: 'multiple', field: '' })


        const extractedData: TaskData[] = editFieldValue.map((item: any) => ({
            id: item.id,
            projectName: item.projectName,
            description: item.description,
            startDate: item.startDate,
            username: item.username
        }));



        statusValue.forEach(status => {
            if (status.value === StatusModel.ON_HOLD && (!status.hasOwnProperty("reason") || status.reason === "" || status.reason === undefined)) {
                snackbarService.next({ data: { open: true, severity: 'error', message: `Task with ID ${status.taskId} is ${status.value} but no reason is provided.` } })
                allReasonsProvided = false
                handleOpenReasonModal()
                setIsStatusChange(true)
            }
        })

        completion.forEach(comp => {
            if (comp.completionDate && (!comp.hasOwnProperty("reason") || comp.reason === '' || comp.reason === undefined)) {
                snackbarService.next({ data: { open: true, severity: 'error', message: `Task with ID ${comp.uniqueId} is completion date is changed but no reason is provided.` } })
                allReasonsProvided = false
                setIsStatusChange(false)
                handleOpenReasonModal()
            }
        })

        if (allReasonsProvided) {
            if (statusValue.length === 0 && completion.length > 0 && extractedData.length === 0) {
                updateDueDate(completion).then((response: any) => {
                    if (response.data.success) {
                        snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
                        setUpdateButtonDisable(true)
                        setSelectedRow([])
                        setCompletion([])
                        setChangeDetection([])
                    } else {
                        snackbarService.next({ data: { open: true, severity: 'error', message: response.data.message } })
                    }
                    fetchData()
                })
            } else if (statusValue.length > 0 && completion.length === 0 && extractedData.length === 0) {
                updateStatus(statusValue).then((response: any) => {
                    if (response.data.success) {
                        setUpdateButtonDisable(true)
                        setStatus([])
                        setSelectedRow([])
                        setChangeDetection([])
                        snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
                    } else {
                        snackbarService.next({ data: { open: true, severity: 'error', message: response.data.message } })
                    }
                    fetchData()
                })
            } else if (statusValue.length > 0 && completion.length > 0 && extractedData.length === 0) {
                Promise.all([
                    updateDueDate(completion),
                    updateStatus(statusValue)
                ])
                    .then(responses => Promise.all(responses.map(response => {
                        return response
                    })))
                    .then(data => {
                        snackbarService.next({ data: { open: true, severity: 'success', message: 'Status and Due date is updated' } })
                        setUpdateButtonDisable(true)
                        setSelectedRow([])
                        setStatus([])
                        setCompletion([])
                        setChangeDetection([])
                    })
                    .catch(error => console.error('Error:', error));
                fetchData()
            } else if (extractedData.length > 0 && completion.length === 0 && statusValue.length === 0) {
                updateTask(extractedData).then((response: any) => {
                    if (response.data.success) {
                        snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
                        setUpdateButtonDisable(true)
                        setSelectedRow([])
                        setChangeDetection([])
                        setEditFieldValue([]) // assuming you have a state update function for extractedData
                    } else {
                        snackbarService.next({ data: { open: true, severity: 'error', message: response.data.message } })
                    }
                    fetchData()
                })
            } else if (extractedData.length > 0 && completion.length > 0 && statusValue.length === 0) {
                Promise.all([
                    updateDueDate(completion),
                    updateTask(extractedData)
                ])
                    .then(responses => Promise.all(responses.map((response: any) => {
                        return response
                    })))
                    .then(data => {
                        snackbarService.next({ data: { open: true, severity: 'success', message: 'Due date and Extracted Data updated' } })
                        setUpdateButtonDisable(true)
                        fetchData()
                        setSelectedRow([])
                        setCompletion([])
                        setChangeDetection([])
                        setEditFieldValue([]) // assuming you have a state update function for extractedData
                    })
                    .catch(error => console.error('Error:', error));
            } else if (extractedData.length > 0 && statusValue.length > 0 && completion.length === 0) {
                Promise.all([
                    updateStatus(statusValue),
                    updateTask(extractedData)
                ])
                    .then(responses => Promise.all(responses.map((response: any) => {
                        return response
                    })))
                    .then(data => {
                        snackbarService.next({ data: { open: true, severity: 'success', message: 'Status and Extracted Data updated' } })
                        fetchData()
                        setUpdateButtonDisable(true)
                        setSelectedRow([])
                        setStatus([])
                        setChangeDetection([])
                        setEditFieldValue([]) // assuming you have a state update function for extractedData
                    })
                    .catch(error => console.error('Error:', error));
            } else if (extractedData.length > 0 && statusValue.length > 0 && completion.length > 0) {
                Promise.all([
                    updateDueDate(completion),
                    updateStatus(statusValue),
                    updateTask(extractedData)
                ])
                    .then(responses => Promise.all(responses.map((response: any) => {
                        return response
                    })))
                    .then(data => {
                        snackbarService.next({ data: { open: true, severity: 'success', message: 'Status, Due date and Extracted Data updated' } })
                        fetchData()
                        setUpdateButtonDisable(true)
                        setSelectedRow([])
                        setStatus([])
                        setCompletion([])
                        setChangeDetection([])
                        setEditFieldValue([]) // assuming you have a state update function for extractedData
                    })
                    .catch(error => console.error('Error:', error));

            }
        }
    }



    const onCellValueChanged = (e: any) => {
        const event = e
        handleChange(event)



    }


    const checkChanges = (obj1: any, obj2: any) => {
        if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
            return obj1 !== obj2;
        }

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) {
            return true;
        }

        for (const key of keys1) {
            if (!obj2.hasOwnProperty(key) || checkChanges(obj1[key], obj2[key])) {
                return true;
            }
        }
        return false;
    }



    const handleChange = (e: any) => {
        const colId = e.column.colId
        const data = e.data
        const taskId = e.data.id
        let previousDate: any;
        let presentDate: any;
        if (colId === 'completionTargets') {
            previousDate = e.oldValue
            presentDate = e.newValue
        }
        let presentStatus: any;
        let previousStatus: any;
        if (colId === 'status' && e.newValue !== undefined && e.oldValue !== undefined) {
            presentStatus = e.newValue
            previousStatus = e.oldValue

        }

        console.log(presentStatus, "Present Status")
        console.log(previousStatus, "Previous Status")
        const updateData = {
            id: data.id,
            username: data.username,
            employeeName: data.employeeName,
            projectId: data.projectId,
            projectName: data.projectName,
            description: data.description,
            createdDate: data.createdDate,
            startDate: data.startDate,
            status: data.status,
            uniqueId: data.uniqueId,
            completionTargets: data.completionTargets,
        }
        const matchTask = originalRowData.find((e: any) => e.id === taskId)
        if (matchTask) {
            const currentChangeStatus = checkChanges(matchTask, updateData)

            if (currentChangeStatus) {

                if (presentStatus === 'On Hold' || presentStatus === 'Completed' || presentStatus === 'Discarded' || presentStatus === 'In Progress' || colId === 'completionTargets') {
                    handleOpenReasonModal()
                }

                setEditFieldValue((prev: any[]) => {
                    // Assuming updateData contains an `id` or unique identifier for checking existence
                    const itemIndex = prev.findIndex(item => item.id === updateData.id);

                    if (itemIndex !== -1) {
                        // If the item exists, update it
                        return prev.map((item, index) =>
                            index === itemIndex ? updateData : item
                        );
                    } else {
                        // If the item does not exist, add it
                        return [...prev, updateData];
                    }
                });

                if (!selectedRow.includes(data.id)) {
                    setSelectedRow([...selectedRow, data.id]);
                }

                setRowParams((preav: any) => {
                    return [...preav, e]
                })


                if (colId === 'status') {
                    setStatus((prevStatus: any) => {
                        const existingIndex = prevStatus.findIndex((s: { taskId: any; }) => s.taskId === taskId);

                        if (existingIndex >= 0) {
                            const updatedStatus = prevStatus.map((s: any, index: any) =>
                                index === existingIndex ? { ...s, value: statusOption.find(item => item.name === presentStatus)?.value } : s
                            );
                            return updatedStatus;
                        }

                        return [...prevStatus, { taskId, uniqueId: data.uniqueId, value: statusOption.find(item => item.name === presentStatus)?.value }];
                    });
                    setChangeDetection(prevState => {
                        const existingTask = prevState.find(task => task.taskId === taskId);
                        const previousCompletion = existingTask ? existingTask.completion : undefined;

                        const existingTaskIndex = prevState.findIndex(task => task.taskId === taskId);

                        if (existingTaskIndex !== -1) {
                            // Task found
                            const existingTask = prevState[existingTaskIndex];

                            // Check if 'status' property is present
                            if (existingTask.hasOwnProperty('status')) {
                                // Update existing task with new 'status' value
                                return [
                                    ...prevState.slice(0, existingTaskIndex),
                                    { ...existingTask, previousStatus: previousStatus, presentStatus: presentStatus, status: { placeholder: 'Status change', name: 'status' } },
                                    ...prevState.slice(existingTaskIndex + 1)
                                ];
                            } else {
                                // Add 'status' property if not present
                                return [
                                    ...prevState.slice(0, existingTaskIndex),
                                    { ...existingTask, previousStatus: previousStatus, presentStatus: presentStatus, status: { placeholder: 'Status change', name: 'status' } },
                                    ...prevState.slice(existingTaskIndex + 1)
                                ];
                            }
                        } else {
                            // Task not found, add a new object with 'status'
                            return [
                                ...prevState,
                                {
                                    taskId,
                                    presentStatus: presentStatus,
                                    uniqueId: updateData.uniqueId,
                                    description: updateData.description,
                                    previousStatus: previousStatus,
                                    status: { placeholder: 'Status change', name: 'status' }, // Add 'status' with the new value
                                    completion: previousCompletion !== undefined ? previousCompletion : undefined
                                }
                            ];
                        }
                    });

                } else if (colId === 'completionTargets') {
                    setCompletion((preav: any) => {
                        const existingIndex = preav.findIndex((s: { taskId: any; }) => s.taskId === taskId);
                        if (existingIndex >= 0) {
                            const updatedCompletion = preav.map((s: any, index: any) =>
                                index === existingIndex ? { ...s, value: presentDate } : s
                            );

                            console.log(updatedCompletion, "Update Statussssss")
                            return updatedCompletion;
                        }
                        const newCompletion = {
                            taskId,
                            completionDate: presentDate,
                            reason: '', // Optional: Set a default reason if needed
                            uniqueId: data.uniqueId, // Generate unique ID
                        };

                        return [...preav, newCompletion];
                    })

                    setChangeDetection(prevState => {
                        const existingTask = prevState.find(task => task.taskId === taskId);
                        const previousStatusValue = existingTask ? existingTask.status : undefined;

                        const existingTaskIndex = prevState.findIndex(task => task.taskId === taskId);

                        if (existingTaskIndex !== -1) {
                            // Task found
                            const existingTask = prevState[existingTaskIndex];

                            // Check if 'completion' property is present and handle update accordingly
                            if (existingTask.hasOwnProperty('completion')) {
                                // Update existing task with new completion value (replace 'completionValue' with your actual completion data)
                                return [
                                    ...prevState.slice(0, existingTaskIndex),
                                    { ...existingTask, previousDate: previousDate, presentDate: presentDate, completion: { placeholder: 'Completion change', name: 'completion' } },
                                    ...prevState.slice(existingTaskIndex + 1)
                                ];
                            } else {
                                // Add 'completion' property if not present and set initial value
                                return [
                                    ...prevState.slice(0, existingTaskIndex),
                                    { ...existingTask, previousDate: previousDate, presentDate: presentDate, completion: { placeholder: 'Completion change', name: 'completion' } },
                                    ...prevState.slice(existingTaskIndex + 1)
                                ];
                            }
                        } else {
                            // Task not found, add a new object with 'completion'
                            return [
                                ...prevState,
                                {
                                    taskId,
                                    presentDate: presentDate,
                                    uniqueId: updateData.uniqueId,
                                    description: updateData.description,
                                    previousDate: previousDate,
                                    status: previousStatusValue !== undefined ? previousStatusValue : undefined, // Existing status logic
                                    completion: { placeholder: 'Completion change', name: 'completion' } // Add completion with initial value
                                }
                            ];
                        }
                    });
                }

                setUpdateButtonDisable(false)

            } else {
                setEditFieldValue([])
                setSelectedRow(selectedRow.filter(item => item !== taskId));
                setUpdateButtonDisable(true)

            }

        }
    }

    console.log(changeDetaction, "change detection")
    console.log(completion, "change completion")

    const getTask = () => {
        console.log('task Done')
        if (Token)
            fetchApi.getUserTask(Token.sub)
    }




    const onGridReady = async (params: any) => {
        const api = params.api
        setGridApi(api)

    }


    return (
        <div className="main">
            <div className="main-container">
                <div className="heading heading-center-space-between height-40">
                    <h2>Task List</h2>
                    <div className='flex-end-center'>
                        <ButtonWrapper buttonType='circle' className='circular-add-button' onClick={onCreateTask} tooltipPlacement='left' tooltipTitle='Create Task' />
                    </div>
                    <ModalWrapper open={openModal} close={handleOpen} children={<AddTask onClose={handleClose} title='Create Task' getTask={getTask} />} />
                </div>
                <div className="cal-40">
                    <div className='task-container'>
                        <div className='task-sub-ag-grid-container'>
                            <AgGridContainer rowData={rowData} columnDefs={colDefs} stopEditingWhenCellsLoseFocus={true} singleClickEdit={false} onCellValueChanged={onCellValueChanged} onGrid={onGridReady} />
                        </div>
                        <div className='task-update-button-container'>
                            <ButtonWrapper title='Update' id='submit-button' variant='contained' type='submit' onClick={handleUpdateTask} disable={updateButtonDisable} showCount={selectedRow && selectedRow.length > 0 ? selectedRow.length : 0} />
                        </div>
                    </div>
                </div>
                <ModalWrapper open={openReasonModal} close={handleCloseReasonModal} children={!isViewReason ? <AddStatusChangeReason onClose={handleCloseReasonModal} params={rowParams} changeDetection={changeDetaction} handleChangeStatusReason={handleChangeReason} isStatusChange={isSatusChange} type={type} /> : <ViewTaskReason reason={reasonDetails} onClose={handleCloseReasonModal} title={detailsTitle} />} />
            </div>
        </div>

    )
}

export default TaskList


