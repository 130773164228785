import React, { useEffect } from 'react'
import { Routes, Route } from "react-router-dom"
import User from '../container/user/user'
import { Login } from '../container/login/login'
import AdminLayout from '../container/admin/admin'
import AdminDashboard from '../pages/admin/dashboard/dashboard'
import AdminEmployee from '../pages/admin/employee/employee'
import AdminAttendance from '../pages/admin/attendance/attendance'
import AdminLeave from '../pages/admin/leave/leave'
import AdminPayroll from '../pages/admin/payroll/payroll'
import UserAttendance from '../pages/user/attendance/attendance'
import UserLeave from '../pages/user/leave/leave'
import UserPayroll from '../pages/user/payroll/payroll'
import UserDashboard from '../pages/user/dashboard/dashboard'
import Holiday from '../pages/admin/holiday/holiday'
import UserHoliday from '../pages/user/holiday/holiday'
import UserAccount from '../pages/user/account/account'
import UserLeaveReview from '../pages/user/leave/leave-review/leave-review'
import LeaveReview from '../pages/admin/leave/leave-review/LeaveReview'
import { SalarySlip } from '../pages/user/payroll/salarySlip/salarySlip'
import AdminsalarySlip from '../pages/admin/payroll/salarySlip/salarySlip'
import AdminAccount from '../pages/admin/account/account'
import TeamManagement from '../pages/admin/teamManagement/teamManagement'
import UserTeamManagement from '../pages/user/teamManagement/teamManagement'
import UserWorkFromHome from '../pages/user/leave/work-from-home/workFromHome'
import WorkFromHome from '../pages/admin/leave/work-from-home/workFromHome'
import LopAttendanceLeaveSummary from '../pages/admin/attendance/lopAttendanceLeaveSummary.tsx/lopAttendanceLeaveSummary'
import UserLopAttendanceSummary from '../pages/user/attendance/lopAttendanceSummary/lopAttendanceSummary'
import { AuthGuard } from '../_guards/Auth.guard'
import { Role } from '../model/token-model/role.model'
import PageNotFound from '../pages/pageNotFound/pageNotFound'
import { TokenService } from '../services/token.service'
import TaskCalender from '../pages/user/task/taskCalender/taskCalender'
import TaskList from '../pages/user/task/task'
import Task from '../pages/admin/task/task'
import { TaskWrapper } from './route-wrapper'

const AllRoutes = () => {

    const withAuth = (Component: any) => {

        return (props: any) => {
            return <AuthGuard role={Role.ADMIN} element={<Component {...props} />} />;
        };
    };



    return (
        <>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/admin/" element={<AuthGuard role={Role.ADMIN} element={<AdminLayout />} />} >
                    <Route path='dashboard' element={<AdminDashboard />} />
                    <Route path='employee' element={<AdminEmployee />} />
                    <Route path='attendance' element={<AdminAttendance />} />
                    <Route path='attendanceSummary' element={<LopAttendanceLeaveSummary />} />
                    <Route path='leave' element={<UserLeave />} />
                    <Route path='employeeLeave' element={<AdminLeave />} />
                    <Route path='leaveDetails' element={<AdminLeave />} />
                    <Route path='account' element={<AdminAccount />} />
                    <Route path='leaveReview' element={<LeaveReview />} />
                    <Route path='workFromHome' element={<WorkFromHome />} />
                    <Route path='payroll' element={<AdminPayroll />} />
                    <Route path='salaryDetails' element={<AdminPayroll />} />
                    <Route path='salarySlip' element={<AdminsalarySlip />} />
                    <Route path='holiday' element={<Holiday />} />
                    <Route path='teamManagement' element={<TeamManagement />} />
                    <Route path='task' element={<Task />} />
                    <Route path='taskList' element={<TaskList />} />
                </Route>
                <Route path="/user/" element={<AuthGuard role={TokenService.getRole() === Role.USER ? Role.USER : Role.COREMEMBERS} element={<User />} />} >
                    <Route path='dashboard' element={<UserDashboard />} />
                    <Route path='attendance' element={<UserAttendance />} />
                    <Route path='attendanceSummary' element={<UserLopAttendanceSummary />} />
                    <Route path='account' element={<UserAccount />} />
                    <Route path='leave' element={<UserLeave />} />
                    <Route path='leaveDetails' element={<UserLeave />} />
                    <Route path='leaveReview' element={<UserLeaveReview />} />
                    <Route path='workFromHome' element={<UserWorkFromHome />} />
                    <Route path='payroll' element={<UserPayroll />} />
                    <Route path='salaryDetails' element={<UserPayroll />} />
                    <Route path='salarySlip' element={<SalarySlip />} />
                    <Route path='holiday' element={<UserHoliday />} />
                    <Route path='teamManagement' element={<UserTeamManagement />} />
                    <Route path='taskList' element={<TaskList />} />
                    <Route path='task' element={<TaskWrapper />} />
                </Route>
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </>
    )
}

export default AllRoutes