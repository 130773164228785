import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import './holiday.css'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import FetchApi from '../../../fetch-api/fetch-api';
import { Stack } from '@mui/system';
import AgGridContainer from '../../../componant/gridContainer/gridContainer';


const UserHoliday = () => {
  const fetchApi = FetchApi()
  const [rowData, setRowData] = useState([])
  const state = useSelector((state: any) => state.allEmployee)

  useEffect(() => {
    if (state.holiday.length > 0) {
      setRowData(state.holiday)
      console.log(state.holiday, "Holiday")
    }
  }, [state])
  useEffect(() => {
    fetchApi.fetchHoliday()
  }, [])
  const [columnDefs]: any[] = useState([
    {
      headerName: "S.No",
      field: "sno",
      width: 100,
    },
    {
      headerName: "Date",
      field: "date",
      width: 415,
      minWidth: 110,
      flex: 1,
      filter: true,

    },
    {
      headerName: "Reasons",
      field: "reason",
      minWidth: 130,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      filter: true,
      cellClass: 'flex-start-center',

    }
  ])

  return (
    <div className='main'>
      <div className='main-container' >
        <div className='heading heading-start-center height-40'>
          <h2>Holiday</h2>
        </div>
        <div className='cal-40'>
          <div className='holiday-list-container'>
            <div className="table-wrapper">

              <AgGridContainer rowData={rowData} columnDefs={columnDefs} />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default UserHoliday