import { deleteData, get, put } from "./api-services";
import { post } from "./api-services";
const baseUrl = process.env.REACT_APP_ENDPOINT;
const baseUrl1 = process.env.REACT_APP_ENDPOINT1;
console.log(baseUrl, "Base Url")
console.log(baseUrl1, "Base Url1")
const localEmployeeUrl = "/employee";

export const getAllEmployee = (): Promise<Response> => {
    return get(`${baseUrl}${localEmployeeUrl}/getEmployees`);
};

export const getAttendance = (): Promise<Response> => {
    return get(`${baseUrl}/attendance/getEmpAttendance`);
};
export const getAllEmployeeAttendance = (): Promise<Response> => {
    return get(`${baseUrl}/attendance/getAllAttendence`);
};

export const isActiveClock = () => {
    return get(`${baseUrl}/attendance/isActive`)
}
export const getAllLeaveDetails = () => {
    return get(`${baseUrl}/leave/getAdminLeave`)
}
export const getEmployeeLeaveDetails = (username: any) => {
    return get(`${baseUrl}/leave/getEmployeeLeave?username=${username}`)
}

export const getAllLeaveOfEmp = (): Promise<Response> => {
    return get(`${baseUrl}/leave/getAllLeave`);
};
export const getAllLeaveOfAdmin = (): Promise<Response> => {
    return get(`${baseUrl}/leave/getAdminLeave`);
};
export const getAllLeaveOfEmployee = (username: any): Promise<Response> => {
    return get(`${baseUrl}/leave/getEmpLeveReview?username=${username}`);
};
export const getEmployeeSalary = (username: any): Promise<Response> => {
    return get(`${baseUrl}/salary/getEmpSalary?username=${username}`);
};



export const postEmployee = (data: any): Promise<Response> => {
    return post(`${baseUrl}${localEmployeeUrl}/addNewUser`, data);
};
export const postLogin = (data: any): Promise<Response> => {
    return post(`${baseUrl}${localEmployeeUrl}/generateToken`, data);
};
export const postClockIn = (data: any, username: any): Promise<Response> => {
    return post(`${baseUrl}/attendance/clock-in?username=${username}`, {
        agenda: data,
    });
};
export const postClockOut = (data: any, username: any): Promise<Response> => {
    return post(`${baseUrl}/attendance/clock-out?username=${username}`, {
        workUpdate: data,
    });
};

export const postForgotPassword = (username: any): Promise<Response> => {
    console.log(username)
    return post(`${baseUrl}/employee/forgotPassword?username=${username}`, {});
};
export const resetPassword = (username: any, oldpassword: any, newpassword: any): Promise<Response> => {
    const encodedOldPassword = encodeURIComponent(oldpassword)
    const encodedNewPassword = encodeURIComponent(newpassword)
    return post(`${baseUrl}/employee/updatePassword?username=${username}&oldpassword=${encodedOldPassword}&newpassword=${encodedNewPassword}`, {});
};

export const uploadLeaveAttachment = (leaveId: number, data: any) => {
    let fd = new FormData();
    fd.append('file', data)
    return post(`${baseUrl}/leave/upload?leaveId=${leaveId}`, fd)
}
export const downloadLeaveAttachment = (id: any): Promise<Response> => {
    return get(`${baseUrl}/leave/downloadLeaveAttachment?leaveId=${id}`, { responseType: "blob" });
};

export const deleteSingleEmployee = (id: any): Promise<Response> => {
    return deleteData(
        `${baseUrl}${localEmployeeUrl}/deleteEmployee?employee_id=${id}`
    );
};


export const rejectLeave = (id: any): Promise<Response> => {
    return put(`${baseUrl}/leave/reject?leaveId=${id}`, {});
};
export const approvedLeave = (id: any): Promise<Response> => {
    return put(`${baseUrl}/leave/approve?leaveId=${id}`, {});
};
export const canceledLeave = (id: any): Promise<Response> => {
    return put(`${baseUrl}/leave/cancel?leaveId=${id}`, {});
};


export const postLeaveApply = (data: any, employeeEmail: any): Promise<Response> => {
    return post(`${baseUrl}/leave/applyLeave?username=${employeeEmail}`, data);
};
export const breakIn = (username: any): Promise<Response> => {
    return post(`${baseUrl}/attendance/break-in?username=${username}`, {});
};
export const breakOut = (username: any): Promise<Response> => {
    return post(`${baseUrl}/attendance/break-out?username=${username}`, {});
};

// /api/aeelv/getAllLeave;






/*Holiday Api*/
export const getHoliday = (): Promise<Response> => {
    return get(`${baseUrl}/holidays/AllHolidays`);
};
export const createHoliday = (data: any): Promise<Response> => {
    return post(`${baseUrl}/holidays/create`, data);
};

export const deleteHoliday = (id: any): Promise<Response> => {
    return deleteData(`${baseUrl}/holidays/delete?id=${id}`)
}
export const updateHoliday = (id: any, data: any): Promise<Response> => {
    return put(`${baseUrl}/holidays/update?id=${id}`, data);
};

export const editEmployeeDetails = (employeeId: any, data: any): Promise<Response> => {
    return put(`${baseUrl}/employee/updateEmployee?employee_id=${employeeId}`, data);

};
export const getEmployee = (employeeId: any): Promise<Response> => {
    return get(`${baseUrl}/employee/getEmployeeDetailsById?employee_id=${employeeId}`);

};

export const getNoOfLeaveDay = (fromDate: any, toDate: any): Promise<Response> => {
    return get(`${baseUrl}/leave/noofworkingdays?startdate=${fromDate}&enddate=${toDate}`)
}

export const getSingleEmployeeDetails = (username: any): Promise<Response> => {
    return get(`${baseUrl}/employee/getEmployeePersonalDetailByUsername?username=${username}`)
}

export const updateSingleEmployeeDetails = (username: any, data: any): Promise<Response> => {
    return put(`${baseUrl}/employee/updateEmployeePersonalDetailByUsername?username=${username}`, data);
};
export const updateSalarySlipAmountDetails = (empId: any, data: any): Promise<Response> => {
    return put(`${baseUrl}/salary/updateSalarySlipAmountDetails?employee_id=${empId}`, data);
};



export const getAllEmployeeSalaryDetails = (): Promise<Response> => {
    return get(`${baseUrl}/salary/getAllEmpSalary`)
}



export const getAllEmplyeeSalarySlipDetails = (date: any): Promise<Response> => {
    return get(`${baseUrl}/salary/getAllEmpSalarySlipDetails?date=${date}`)
}

export const downloadSalarySlip = (empId: any, date: any): Promise<Response> => {
    return get(`${baseUrl}/salary/downloadSalarySlip?associateId=${empId}&salSlipGenDate=${date}`, { responseType: "blob" })

}

export const generateSalarySlip = (id: any, date: any): Promise<Response> => {
    return post(`${baseUrl}/salary/generateSalarySlip?associateId=${id}&date=${date}`, {})
}
export const generateSelectedEmployeeSalarySlip = (date: any, data: any): Promise<Response> => {
    return post(`${baseUrl}/salary/generateManySalarySlip?date=${date}`, data)
}
export const downloadSelectedEmployeeSalarySlip = (date: any, data: any): Promise<Response> => {
    return post(`${baseUrl}/salary/downloadManySalarySlip?date=${date}`, data, { responseType: "blob" })
}
export const getEmployeeAllSalarySlip = (date: any): Promise<Response> => {
    return get(`${baseUrl}/salary/getEmpAllSalarySlip?date=${date}`)
}

/*Team Api*/
export const createTeam = (data: any): Promise<Response> => {

    return post(`${baseUrl}/team/createTeam`, data)
}

export const getTeamDetails = (): Promise<Response> => {
    return get(`${baseUrl}/team/getAllProjTeamsDetails`)
}

export const deleteTeam = (id: any): Promise<Response> => {
    return deleteData(`${baseUrl}/team/deleteProjTeamsDetails?id=${id}`)
}

export const updateTeamDetails = (projectId: any, data: any): Promise<Response> => {
    console.log(projectId, "Id")
    return put(`${baseUrl}/team/updateProjTeamDetails?id=${projectId}`, data);
};

export const getSingleEmployeeTeamDetails = (): Promise<Response> => {
    return get(`${baseUrl}/team/getTeamDetails`)
}
export const getUserTeam = (): Promise<Response> => {
    return get(`${baseUrl}/team/getAllUserTeam`)
}

export const searchEmployeeByLetter = (searchData: any, controller?: AbortController): Promise<Response> => {
    console.log(controller)
    return get(`${baseUrl}/team/searchUser?searchString=` + searchData, controller)
}


/*Summary*/
export const getSummaryPendingLeave = (): Promise<Response> => {
    return get(`${baseUrl}/leave/findPendingLeave`)
}
export const getBirthdaySummaryDetails = (): Promise<Response> => {
    return get(`${baseUrl}/employee/getBirthdayBoy`)
}

export const getLeaveSummaryDetails = (): Promise<Response> => {
    return get(`${baseUrl}/leave/findTodayOnLeave`)
}

export const getProjectSummaryDetails = (): Promise<Response> => {
    return get(`${baseUrl}/team/findTeamsWithProjectEndDateAfterSevenDays`)
}

export const getAbsentEmployeeSummaryDetails = (): Promise<Response> => {
    return get(`${baseUrl}/employee/getAbsentEmployees`)
}

export const createLeave = (data: any): Promise<Response> => {
    return post(`${baseUrl}/leave/createLeave`, data)
}

export const getLeave = (): Promise<Response> => {
    return get(`${baseUrl}/leave/getLeave`)
}

export const updateLeave = (data: any, id: number): Promise<Response> => {
    return put(`${baseUrl}/leave/editLeave?id=${id}`, data)
}

/*Work from Home APi*/
export const getWorkFromHomeDetails = (): Promise<Response> => {
    return get(`${baseUrl}/attendance/getAllWFH`)
}

export const approvedWorkFromHome = (id: any): Promise<Response> => {
    return put(`${baseUrl}/attendance/approveWFH?wfhId=${id}`, {})
}

export const rejectWorkFromHome = (id: any): Promise<Response> => {
    return put(`${baseUrl}/attendance/rejectWFH?wfhId=${id}`, {})
}

export const createWorkFromHome = (data: any): Promise<Response> => {
    return post(`${baseUrl}/attendance/workfromhome`, data)
}

export const getWorkFromHomeForUser = (): Promise<Response> => {
    return get(`${baseUrl}/attendance/getAllWFHForUser`)
}

export const getWorkFromHomeSummaryDetails = () => {
    return get(`${baseUrl}/attendance/getTodayWFH`)
}

/*Lop Details*/
export const getAttendanceSummaryWithLop = (): Promise<Response> => {
    return get(`${baseUrl}/attendance/getAllLOP`)
}

export const getEmpAttendanceSummaryWithLop = (): Promise<Response> => {
    return get(`${baseUrl}/attendance/getAllEmpLOP`)
}

/*Menu*/

export const getConfiguration = (): Promise<Response> => {
    return get(`${baseUrl}/menu/byRole`)
}


/*Task*/
export const createTask = (task: any): Promise<Response> => {
    return post(`${baseUrl1}/worktracker/task`, task)
}
export const getSingleUserTask = (empId: any): Promise<Response> => {
    return get(`${baseUrl1}/worktracker/task/employee/${empId}`)
}

export const updateStatus = (updatedStatus: any): Promise<Response> => {
    return put(`${baseUrl1}/worktracker/status`, updatedStatus)
}
export const updateDueDate = (updatedDueDate: any): Promise<Response> => {
    return put(`${baseUrl1}/worktracker/completiontarget`, updatedDueDate)
}

export const getTaskHistory = (empId: any, startDate: any): Promise<Response> => {
    return get(`${baseUrl1}/worktracker/task/date/employee/${empId}?startDate=${startDate}`)
}
export const getAllUserTask = (): Promise<Response> => {
    return get(`${baseUrl1}/worktracker/task`)
}

export const getLastSeveDaysReaminingTask = (empId: any): Promise<Response> => {
    return get(`${baseUrl1}/worktracker/task/pending/date/employee/${empId}`)
}


export const updateTask = (data: any): Promise<Response> => {
    return put(`${baseUrl1}/worktracker/task/update`, data)
}
